/** @jsx jsx */
import { jsx } from "theme-ui"
import Divider from "../elements/divider"
import Inner from "../elements/inner"
import Content from "../elements/content"
import SVG from "./svg"
// @ts-ignore
import ProjectsMDX from "../sections/projects"

const Projects = ({ offset, factor = 2 }: { offset: number; factor?: number }) => (
  <div>
    <Divider
      bg="linear-gradient(to right, #54B4D8 0%, #437DC0 45%)"
      sx={{ clipPath: `polygon(0 15%, 100% 15%, 100% 100%, 0 100%)` }}
      height={400}
      speed={0}
      offset={offset}
      factor={factor}
    />
    <Content  speed={0.15} offset={1} factor={2}>
      <Inner>
        <div id="portfolio"
        >
        </div>
      </Inner>
    </Content>
    <Content  speed={0.15} offset={1.75} factor={factor}>
      <Inner>
        <div
          sx={{
            display: `grid`,
            gridGap: [4, 4, 4, 5],
            gridTemplateColumns: [`1fr`],
            h2: { gridColumn: `-1/1`, color: `white !important` },
          }}
        >
          <ProjectsMDX />
        </div>
      </Inner>
    </Content>
    <Divider
      bg="linear-gradient(to right, #54B4D8 0%, #437DC0 45%)"
      speed={0}
      offset={1}
      factor={factor}
    />
    <Divider
      bg="linear-gradient(to right, #54B4D8 0%, #437DC0 45%)"
      speed={0}
      offset={2.3}
      factor={factor}
    />

  </div>
)

export default Projects
