import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`About Me`}</h2>
    <blockquote>
      <p parentName="blockquote">{`In addition to my strong interest in software development and creating real world solutions, I have many other hobbies and passions, some of which have also translated into business opportunities.`}</p>
    </blockquote>
    <p>{`In 2018 I co-founded `}<a href="https://rechargeautomotive.com" target="_blank">{`Recharge Automotive LLC`}</a>{`, an automotive event/media company that specializes in shows and rallies for automotive enthusiasts. `}</p>
    <p>{`I am a published photographer  and have done many freelance projects revolving around software, live production, photography, videography `}<a href="https://www.flickr.com/photos/147710121@N04/" target="_blank">{`Flickr`}</a>{` | `}<a href="https://unsplash.com/@maxbrinton" target="_blank">{`Unsplash `}</a>{`.
Some of my notable clients include the band Disturbed, the artist Drake, high-end dealerships such as Porsche Beachwood, and high-end car collectors. On the more software related endeavours, some people and companies I have worked with are Cardale Jones with Plus2University, the company FileVine, and UC Berkely alumn Ali Ansari with Micro.1.`}</p>
    <p>{`Throughout all of my hobbies and business endevaours I have always jumped at the opportunity to work with others to create something cool. Out of all of these experiences, I think the most beneficial aspect of them has simply been meeting new people and creating lasting friendships.
I have worked hard in life and have had many experiences, all of which have helped me become a better problem solver and in turn, a better technologist/software developer.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      