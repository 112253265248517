import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const SVG = makeShortcode("SVG");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Get in touch`}</h2>
    <p>{`Message me on `}<a href="https://www.linkedin.com/in/maxbrinton/" target="_blank">{`LinkedIn`}</a>{` or `}<a href="https://osu.joinhandshake.com/users/13991959" target="_blank">{`Handshake`}</a>{`.`}</p>
    <SVG icon="triangle" hiddenMobile width={48} stroke color="icon_orange" left="10%" top="20%" mdxType="SVG" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      