import { keyframes, css,  } from "@emotion/core"
import styled from "@emotion/styled"

const wave = keyframes`
  0% {
    d: path("M 0 100 Q 250 50 400 200 Q 550 350 800 300 L 800 0 L 0 0 L 0 100 Z");
  }
  50% {
    d: path("M 0 100 Q 200 150 400 200 Q 600 250 800 300 L 800 0 L 0 0 L 0 100 Z");
  }
  100% {
    d: path("M 0 100 Q 150 350 400 200 Q 650 50 800 300 L 800 0 L 0 0 L 0 100 Z");
  }
`

const twinkle1 = keyframes`
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(0);
    opacity: 0.9;
  }
`

const twinkle2 = keyframes`
  from {
    transform: translateY(0);
    opacity: 1;
    
  }
  to {
    transform: translateY(0);
    opacity: 0.3;
  }
`

const twinkle3 = keyframes`
  from {
    transform: translateY(0);
    opacity: 0.5;
  }
  to {
    transform: translateY(0);
    opacity: 0.1;
  }
`


const twinkle1Animation = css`
  ${twinkle1} 5s ease-in-out normal;
`

const twinkle2Animation = css`
  ${twinkle2} 2s ease-in-out infinite alternate;
`

const twinkle3Animation = css`
  ${twinkle3} 0.7s ease-in-out infinite alternate;
`

export const Twinkle1 = styled.div`
  animation: ${twinkle1Animation};
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

export const Twinkle2 = styled.div`
  animation: ${twinkle2Animation};
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

export const Twinkle3 = styled.div`
  animation: ${twinkle3Animation};
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`


export const waveAnimation = (length: string) => css`
  animation: ${wave} ${length} linear infinite alternate;
`
