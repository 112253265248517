import React, { useState, useEffect } from "react"
import { Parallax } from "react-spring/renderprops-addons.cjs"
import { window, exists } from "browser-monads"

import Layout from "../components/layout"
import Hero from "../components/hero"
import Projects from "../components/projects"
import About from "../components/about"
import Contact from "../components/contact"





// breakpoints: [`400px`, `600px`, `900px`, `1200px`, `1600px`],
// The template is not responsive and the parallax effect brokens the containers.
// As a work around, use react hooks (useEffect) to calculate the size of the window
// and change number of pages and offset. This should be done calculating the size of each section first
// but in this version is hardcoded based on different tests


const Cara = () => {
  // Client-side Runtime fetch browser width
  const { pages, projectsOffset, projectsFactor, aboutOffset, aboutFactor, contactOffset, contactFactor } = useWindowWidth(); // Our custom Hook
  
  return (
    <Layout>
      <Parallax pages={pages}>
        <Hero offset={0} factor={1} />
        <Projects offset={projectsOffset} factor={projectsFactor} />
        <About offset={aboutOffset} factor={aboutFactor} />
        <Contact offset={contactOffset} factor={contactFactor} />
      </Parallax>
    </Layout>
  )
}

function useWindowWidth() {
  const [width, setWidth] = useState(window.innerWidth);

  let breakpoints = {
    pages: 7, 
    projectsOffset: 2, 
    projectsFactor: 3, 
    aboutOffset: 5, 
    aboutFactor: 2,
    contactOffset: 6, 
    contactFactor: 1,
    width: width
  };

  if(exists(window)){
    useEffect(() => {

      const handleResize = () => setWidth(window.innerWidth);
      window.addEventListener('resize', handleResize);
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    });
  
    switch (true) {
      case (width <= 375):
        breakpoints.pages = 15;
  
        breakpoints.projectsOffset = 2;
        breakpoints.projectsFactor = 9;
  
        breakpoints.aboutOffset = 12;
        breakpoints.aboutFactor = 1;
  
        breakpoints.contactOffset = 14;
        breakpoints.contactFactor = 1;
        break;
      case (width <= 400):
        breakpoints.pages = 12;
  
        breakpoints.projectsOffset = 2;
        breakpoints.projectsFactor = 6;
  
        breakpoints.aboutOffset = 9;
        breakpoints.aboutFactor = 1;
  
        breakpoints.contactOffset = 11;
        breakpoints.contactFactor = 1;
        break;
      case (width <= 600):
        breakpoints.pages = 12;
  
        breakpoints.projectsOffset = 2;
        breakpoints.projectsFactor = 6;
  
        breakpoints.aboutOffset = 9;
        breakpoints.aboutFactor = 1;
  
        breakpoints.contactOffset = 11;
        breakpoints.contactFactor = 1;
        break;
      case (width <= 900):
        //using the default
        break;
      case (width <= 1200):
        //using the default
        break;
      case (width > 2400):
        breakpoints.pages = 6;
  
        breakpoints.projectsOffset = 1;
        breakpoints.projectsFactor = 2;
  
        breakpoints.aboutOffset = 4;
        breakpoints.aboutFactor = 1;
  
        breakpoints.contactOffset = 5;
        breakpoints.contactFactor = 1;
        break;
    }
  }
  
  // console.log("breakpoints", breakpoints.pages, breakpoints.projectsOffset, breakpoints.projectsFactor, breakpoints.aboutOffset, breakpoints.aboutFactor)

  return breakpoints;
}

/*Original theme.
const Cara = () => (
  <Layout>
    <Parallax pages={6}>
      <Hero offset={0} factor={1} />
      <Projects offset={1} factor={2} />
      <About offset={4.5} factor={1} />
      <Contact offset={5} factor={1} />
    </Parallax>
  </Layout>
)
*/

export default Cara