import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Portfolio`}</h2>
    <ProjectCard title="Solutions Architect, CarbonWeb (2022)" link="https://www.carbonweb.co/about" bg="#2d3748" mdxType="ProjectCard">
      <img src={require('../images/carbon2.png')} style={{
        "height": "auto",
        "width": "50%",
        "display": "inline-block",
        "paddingTop": "15px",
        "paddingBottom": "15px",
        "paddingRight": "15px",
        "paddingLeft": "15px",
        "border": "solid 0px #fff",
        "borderRadius": "20px"
      }} alt="img"></img>
      <img src={require('../images/carbonss.png')} style={{
        "height": "auto",
        "width": "50%",
        "display": "inline-block",
        "paddingTop": "20px",
        "paddingBottom": "15px",
        "paddingLeft": "15px",
        "paddingRight": "15px"
      }} alt="img"></img>
      <br />
Since Carbon Web's launch in 2016, I have helped scale the company from concept to a full fledged IT/Technology company. As of today, Carbon Web has 10+ employees and hundreds of clients. 
During Carbon Web's growth, my responsbilities and titles have changed over time, but now I am mainly in charge of SaaS implementations and product development. 
      <br /><br />  
Coming back to CarbonWeb in 2022 has been great. I am now a Solutions Architect and I focus on implementing SaaS products for companies and doing custom development within (or in communication with) that SaaS product. 
I am responsible for drafting the initial solution, and implementing that solution while meeting with the client and creating training documentation for them along the way. 
      <br /><br />
CarbonWeb also has several ongoing projects that are custom developed for monday.com specifically. We are a channel partner so right now monday.com implementations and app development is our core focus and where most of my efforts have been placed
as of recenty. I currently work part time while I finish up my classes at Ohio State and I am also remote. 
      <br /><br />
      <u><i>Technologies/Software:</i></u>   AWS, ReactJs, GraphQL, Node, HTML, CSS, JavaScript, WordPress, Calendly, Monday.com, Google Developers Console, Amazon Web Services, Integromat
    </ProjectCard>
    <ProjectCard title="Project Manager, Moontek" link="https://moontek.io" bg="#2d3748" mdxType="ProjectCard">
      <img src={require('../images/moonteklogo.png')} style={{
        "height": "auto",
        "width": "50%",
        "display": "inline-block",
        "padding": "10%",
        "verticalAlign": "center"
      }} alt="img"></img>
      <img src={require('../images/moontekss.png')} style={{
        "height": "auto",
        "width": "50%",
        "display": "inline-block",
        "paddingTop": "15px",
        "paddingBottom": "15px",
        "paddingRight": "15px",
        "paddingLeft": "15px",
        "border": "solid 0px #fff",
        "borderRadius": "20px"
      }} alt="img"></img>
Upon meeting the founder of moontek through a mutual friend at UC Berkely, I quickly begain working for moontek as a project manager on several different projects right away.
These projects ranged from mobile app, web apps, to static sites. <br /> <br />My responsbilities when managing these projects included everything client related from sales hand-off to
project delivery. I managed the overall project timeline based on the scope of work as well as coordinated meetings between myself and the client for progress updates. I was 
also responsible for developer meetings/weekly standups as well as mediating any blocking issues with the client. Working with oversea developers and clients on different timezones
tested not only my communication and coordination skills, but also pushed me to have a deeper understanding of even the smallest components of each project. This way I am always prepared
to answer a question whether it be for a client or our own developers. 
      <br /><br />
      <u><i>Technologies/Software:</i></u>   ReactJs, Webflow, AWS Amplify, Calendly, Monday.com, Github, Slack.
    </ProjectCard>
    <ProjectCard title="Web Developer, Freelance" bg="#2d3748" mdxType="ProjectCard">
      <br />
      <u>State Industrial Products (2021)</u>
      <br />   
This was a contract position I took for about six months while in school working part time each week. I helped research, design, and implement a front-end web app that tied into 
this company's Sharepoint file system. This tool is to be used by hundreds of sales reps across the country during meetings with potential customers.
      <br /><br />
      <u>Jeti, Inc. (2019) </u>
      <br />  
This was a fun project I worked on briefly at the end of 2019. Jeti is a social media startup that was looking for funding when I designed and implemented a frontend for the admin portal. 
Although Jeti never secured further funding and the project is dead, we were at one time a top 100 social media app on the App Store and had 40k+ users.
      <br /><br />
      <u>CarbonWeb, LLC (2016+) </u>
      <br />  
I began working for CarbonWeb as a junior in high school around the same time that I really began to take interest in programming. As I started off working for this startup, I was making WordPress sites as I self taught html/css/js. 
By 2020 I had learned a ton from working here and had the chance to work on multiple React/Node stacks before I left. I was even able to work on a mobile app project in React Native. 
    </ProjectCard>
    <ProjectCard title="CSE Student, The Ohio State University" link="https://cse.osu.edu/" bg="#2d3748" mdxType="ProjectCard">
      <img src={require('../images/osu2.png')} style={{
        "height": "auto",
        "width": "50%",
        "display": "inline-block",
        "margin": "5%",
        "border": "solid 0px #fff",
        "borderRadius": "20px"
      }} alt="img"></img>
      <img src={require('../images/honda.png')} style={{
        "height": "auto",
        "width": "30%",
        "display": "inline-block",
        "marginTop": "5%",
        "marginBottom": "5%",
        "marginRight": "5%",
        "border": "solid 0px #fff",
        "borderRadius": "20px"
      }} alt="img"></img>
      <br />
Ohio State's Computer Science & Engineering department has immersed me in an environment that not only encourages best practice software development, but it has really given me a solid foundation 
in computing technology as a whole. From Web Apps to Operation Systems, from networking protocols to AI algorithms; OSU strives to give each student an opportunity to not only be a great software engineer, but someone who truly understands
software and how it works. 
      <br /><br />
While providing a strong foundation via course material, OSU attempts to create team or partner projects whenever possible. This has helped me not only learn how to be a software developer, but more importantly I have learned 
how to be a productive software developer on any given team. Being a high value, productive team player in any sort of development life cycle is something I strive for thanks to seeing the benefits of this at OSU.
      <br /><br />
Currently, I am working on a project for Honda Manufacturing of America as apart of my capstone project. This is a python program that involves OCR (image recognition AI) and database modeling and is pulled together by a Tkinter frontend. 
Even being thrown into a project with no existing code and stakeholders with limited software knowledge, myself and my team are currently making great progress on the end deliverable. 
      <br /><br />
      <i>Due to OSU Academic Misconduct policy, student GitHubs cannot be publicly accessed. </i> <br /><br />
      <i><u>Relevant Coursework to Date:</u></i>  Software Requirement Analysis, Web-Apps (Ruby/JavaScript), AI, Principles of Programming Languages, C++, Software I & II (Java), Foundations I & II (algorithm complexity), Systems I, Analog Systems, Digital Logic, and more 
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      