/** @jsx jsx */
import { jsx }  from "theme-ui"
import Divider from "../elements/divider" 
import Inner from "../elements/inner"
import Content from "../elements/content"
import heroBg from "../images/heroBg.svg"
import SVG from "./svg"
import { Twinkle1, Twinkle2, Twinkle3 } from "../styles/animations"
// @ts-ignore
import Intro from "../sections/intro"



const Hero = ({ offset, factor = 1 }: { offset: number; factor?: number }) => (
  <div>
    <Divider speed={0} offset={offset} factor={factor}>
      
    <Divider speed={1.2} offset={offset} factor={factor}>

      <Twinkle1>
        {/*Little Dipper  */}
        <SVG icon="circle"  hiddenMobile width={1}  color="#fff" left="51%" top="19%" />
        <SVG icon="circle"  hiddenMobile width={1.5}  color="#fff" left="51.5%" top="21%" />
        <SVG icon="circle"  hiddenMobile width={1}  color="#fff" left="53%" top="30%" />
        <SVG icon="circle"  hiddenMobile width={1}  color="#fff" left="60%" top="35%" />
        <SVG icon="circle"  hiddenMobile width={1}  color="#fff" left="59%" top="42%" />
        <SVG icon="circle"  hiddenMobile width={1}  color="#fff" left="68%" top="38%" />
        <SVG icon="circle"  hiddenMobile width={1}  color="#fff" left="67%" top="46%" />

        {/*Big Dipper */}
        <SVG icon="circle"  hiddenMobile width={1}  color="#fff" left="80%" top="7%" />
        <SVG icon="circle"  hiddenMobile width={1.5}  color="#fff" left="88%" top="5%" />
        <SVG icon="circle"  hiddenMobile width={1}  color="#fff" left="91%" top="25%" />
        <SVG icon="circle"  hiddenMobile width={1}  color="#fff" left="85%" top="30%" />
        <SVG icon="circle"  hiddenMobile width={1}  color="#fff" left="84.5%" top="45%" />
        <SVG icon="circle"  hiddenMobile width={1}  color="#fff" left="84%" top="60%" />
        <SVG icon="circle"  hiddenMobile width={1}  color="#fff" left="89%" top="72%" />

        {/*Leo*/}
        <SVG icon="circle"  hiddenMobile width={1}  color="#fff" left="30%" top="2%" />
        <SVG icon="circle"  hiddenMobile width={1}  color="#fff" left="25%" top="2%" />
        <SVG icon="circle"  hiddenMobile width={1}  color="#fff" left="23%" top="26%" />
        <SVG icon="circle"  hiddenMobile width={1}  color="#fff" left="28%" top="35%" />
        <SVG icon="circle"  hiddenMobile width={1}  color="#fff" left="36%" top="34%" />
        <SVG icon="circle"  hiddenMobile width={1}  color="#fff" left="42%" top="43%" />
        <SVG icon="circle"  hiddenMobile width={1}  color="#fff" left="18%" top="68%" />
        <SVG icon="circle"  hiddenMobile width={1}  color="#fff" left="12%" top="53%" />
        <SVG icon="circle"  hiddenMobile width={1}  color="#fff" left="11%" top="80%" />
      </Twinkle1>
    
      <Twinkle2>
        {/*North Star*/}
        <SVG icon="circle"  width={2}  color="#fff" left="50%" top="10%" />
        {/*Randoms*/}
        <SVG icon="circle"  width={1.5}  color="#fff" left="54.5%" top="31.2%" />
        <SVG icon="circle"  width={1.5}  color="#fff" left="56%" top="33%" />
        <SVG icon="circle"  width={1.5}  color="#fff" left="64%" top="40%" />
        <SVG icon="circle"  width={1.5}  color="#fff" left="71%" top="39%" />
        <SVG icon="circle"  width={1.5}  color="#fff" left="71%" top="50%" />
        <SVG icon="circle"  width={1.5}  color="#fff" left="70%" top="48%" />
        <SVG icon="circle"  width={1.5}  color="#fff" left="69%" top="47%" />
        <SVG icon="circle"  width={1.5}  color="#fff" left="75%" top="46%" />
        <SVG icon="circle"  width={1.5}  color="#fff" left="74%" top="40%" />
        <SVG icon="circle"  width={1.5}  color="#fff" left="74%" top="40%" />
        <SVG icon="circle"  width={1.5}  color="#fff" left="73.4%" top="30%" />
        <SVG icon="circle"  width={1.5}  color="#fff" left="2%" top="22%" />
        <SVG icon="circle"  width={1.5}  color="#fff" left="61%" top="41%" />
        <SVG icon="circle"  width={1.5}  color="#fff" left="12%" top="7%" />
        <SVG icon="circle"  width={1.5}  color="#fff" left="89%" top="90%" />
        <SVG icon="circle"  width={1.5}  color="#fff" left="87%" top="65%" />
        <SVG icon="circle"  width={1.5}  color="#fff" left="14%" top="90%" />
        <SVG icon="circle"  width={1.5}  color="#fff" left="87%" top="76%" />
        <SVG icon="circle"  width={1.5}  color="#fff" left="66%" top="12%" />
        <SVG icon="circle"  width={1.5}  color="#fff" left="57%" top="66%" />
        <SVG icon="circle"  width={1.5}  color="#fff" left="22%" top="67%" />
        <SVG icon="circle"  width={1.5}  color="#fff" left="6%" top="45%" />
        <SVG icon="circle"  width={1.5}  color="#fff" left="30%" top="19%" />
        <SVG icon="circle"  width={1.5}  color="#fff" left="30%" top="56%" />
        <SVG icon="circle"  width={1.5}  color="#fff" left="69%" top="30%" />
        <SVG icon="circle"  width={1.5}  color="#fff" left="48%" top="99%" />
        <SVG icon="circle"  width={1.5}  color="#fff" left="5%" top="87%" />
        <SVG icon="circle"  width={1.5}  color="#fff" left="30%" top="4%" />
        <SVG icon="circle"  width={1.5}  color="#fff" left="9%" top="79%" />
        <SVG icon="circle"  width={1.5}  color="#fff" left="87%" top="99%" />
        <SVG icon="circle"  width={1.5}  color="#fff" left="23%" top="10%" />
        <SVG icon="circle"  width={1.5}  color="#fff" left="80%" top="2%" />
        <SVG icon="circle"  width={1.5}  color="#fff" left="62%" top="39%" />
        <SVG icon="circle"  width={1.5}  color="#fff" left="35%" top="80%" />
        <SVG icon="circle"  width={1.5}  color="#fff" left="65%" top="65%" />
        <SVG icon="circle"  width={1.5}  color="#fff" left="67%" top="60%" />
        <SVG icon="circle"  width={1.5}  color="#fff" left="32%" top="90%" />
        <SVG icon="circle"  width={1.5}  color="#fff" left="46%" top="91%" />
        <SVG icon="circle"  width={1.5}  color="#fff" left="54%" top="88%" />
        <SVG icon="circle"  width={1.5}  color="#fff" left="22%" top="96%" />
        <SVG icon="circle"  width={1.5}  color="#fff" left="40%" top="65%" />
        <SVG icon="circle"  width={1.5}  color="#fff" left="95%" top="65%" />
        <SVG icon="circle"  width={1.5}  color="#fff" left="98%" top="54%" />
        <SVG icon="circle"  width={1.5}  color="#fff" left="91%" top="35%" />
        <SVG icon="circle"  width={1.5}  color="#fff" left="94%" top="50%" />
        <SVG icon="circle"  width={1.5}  color="#fff" left="99%" top="1%" />
        <SVG icon="circle"  width={1.5}  color="#fff" left="75%" top="95%" />
        <SVG icon="circle"  width={1.5}  color="#fff" left="69%" top="90%" />
        <SVG icon="circle"  width={1.5}  color="#fff" left="85%" top="88%" />
        <SVG icon="circle"  width={1.5}  color="#fff" left="93%" top="91%" />
      </Twinkle2>

      <Twinkle3>
        {/*Randoms*/}
        <SVG icon="circle" hiddenMobile  width={1.5}  color="#fff" left="57%" top="89%" />
        <SVG icon="circle" hiddenMobile width={1.5}  color="#fff" left="7%" top="33%" />
        <SVG icon="circle" hiddenMobile width={1.5}  color="#fff" left="17%" top="83%" />
        <SVG icon="circle" hiddenMobile width={1.5}  color="#fff" left="67%" top="39%" />
        <SVG icon="circle" hiddenMobile width={1.5}  color="#fff" left="38%" top="69%" />
        <SVG icon="circle" hiddenMobile width={1.5}  color="#fff" left="38%" top="98%" />
        <SVG icon="circle" hiddenMobile width={1.5}  color="#fff" left="32%" top="93%" />
        <SVG icon="circle" hiddenMobile width={1.5}  color="#fff" left="69%" top="6%" />
        <SVG icon="circle" hiddenMobile width={1.5}  color="#fff" left="52%" top="67%" />
        <SVG icon="circle" hiddenMobile width={1.5}  color="#fff" left="5%" top="85%" />
        <SVG icon="circle" hiddenMobile width={1.5}  color="#fff" left="95%" top="42%" />
        <SVG icon="circle" hiddenMobile width={1.5}  color="#fff" left="87%" top="70%"/>
        <SVG icon="circle" hiddenMobile width={1.5}  color="#fff" left="99%" top="75%" />
        <SVG icon="circle" hiddenMobile width={1.5}  color="#fff" left="15%" top="8%"/>
        <SVG icon="circle" hiddenMobile width={1.5}  color="#fff" left="95%" top="35%" />
        <SVG icon="circle" hiddenMobile width={1.5}  color="#fff" left="62%" top="82%" />
        <SVG icon="circle" hiddenMobile width={1.5}  color="#fff" left="76%" top="57%" />
        <SVG icon="circle" hiddenMobile width={1.5}  color="#fff" left="35%" top="67%" />
        <SVG icon="circle" hiddenMobile width={1.5}  color="#fff" left="19%" top="78%" />
        <SVG icon="circle" hiddenMobile width={1.5}  color="#fff" left="67%" top="56%" />
        <SVG icon="circle" hiddenMobile width={1.5}  color="#fff" left="41%" top="4%" />
        <SVG icon="circle" hiddenMobile width={1.5}  color="#fff" left="12%" top="27%"/>
        <SVG icon="circle" hiddenMobile width={1.5}  color="#fff" left="57%" top="84%" />
        <SVG icon="circle" hiddenMobile width={1.5}  color="#fff" left="68%" top="31%" />
        <SVG icon="circle" hiddenMobile width={1.5}  color="#fff" left="14%" top="3%" />
        <SVG icon="circle" hiddenMobile width={1.5}  color="#fff" left="15%" top="91%" />
        <SVG icon="circle" hiddenMobile width={1.5}  color="#fff" left="6%" top="88%"/>
        <SVG icon="circle" hiddenMobile width={1.5}  color="#fff" left="99%" top="91%"/>
        <SVG icon="circle" hiddenMobile width={1.5}  color="#fff" left="98%" top="2%"/>
        <SVG icon="circle" hiddenMobile width={1.5}  color="#fff" left="98%" top="49%" />
        <SVG icon="circle" hiddenMobile width={1.5}  color="#fff" left="25%" top="57%" />
        <SVG icon="circle" hiddenMobile width={1.5}  color="#fff" left="77%" top="56%"/>
        <SVG icon="circle" hiddenMobile width={1.5}  color="#fff" left="51%" top="93%" />
        <SVG icon="circle" hiddenMobile width={1.5}  color="#fff" left="81%" top="96%" />
        <SVG icon="circle" hiddenMobile width={1.5}  color="#fff" left="49%" top="79%" />
        <SVG icon="circle" hiddenMobile width={1.5}  color="#fff" left="23%" top="94%" />
        <SVG icon="circle" hiddenMobile width={1.5}  color="#fff" left="96%" top="84%" />
        <SVG icon="circle" hiddenMobile width={1.5}  color="#fff" left="77%" top="22%" />
        <SVG icon="circle" hiddenMobile width={1.5}  color="#fff" left="94%" top="70%" />
        <SVG icon="circle" hiddenMobile width={1.5}  color="#fff" left="7%" top="39%" />
        <SVG icon="circle" hiddenMobile width={1.5}  color="#fff" left="99%" top="97%" />
        <SVG icon="circle" hiddenMobile width={1.5}  color="#fff" left="64%" top="59%" />
        <SVG icon="circle" hiddenMobile width={1.5}  color="#fff" left="34%" top="22%" />
        <SVG icon="circle" hiddenMobile width={1.5}  color="#fff" left="73%" top="61%" />
        <SVG icon="circle" hiddenMobile width={1.5}  color="#fff" left="51%" top="11%" />
        <SVG icon="circle" hiddenMobile width={1.5}  color="#fff" left="17%" top="29%"/>
      </Twinkle3>

    </Divider>
        
      {/* Background Image*/}
      <img src={ heroBg } style={{ width: "80%", position: "fixed", right:0, bottom:-20}} />
    </Divider>
    <Content sx={{ variant: `texts.bigger` }} speed={0.4}  offset={offset} factor={factor}>
      <Inner>
        <Intro />
        <button
          sx={{ variant: `buttons.outline` }}
          onClick={() => document.getElementById('portfolio').scrollIntoView({block: "nearest", behavior: 'smooth'})}
          type="button"
          aria-label="Explore Portfolio"
          color="#63b3ed"
        >
          Explore Portfolio
        </button>
      </Inner>
    </Content>
   
  </div>
)

export default Hero
